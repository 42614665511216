<template>
  <div class="downloadChart">
    "test text"
    <highcharts v-if="chartdata" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
export default {
  props: {
    chartdata: Array,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
          height: 180,
        },

        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          labels: {
            text: "2022",
            style: {
              color: "#001E4C",
            },
          },
        },
        yAxis: {
          labels: {
            enabled: false,
          },
          title: {
            text: "2022",
            style: {
              color: "#001E4C",
            },
          },

          gridLineWidth: 0,
        },
        plotOptions: {
          series: {
            color: "#0091FF",
          },
        },
        series: [
          {
            name: "Download",
            data: this.chartdata,
          },
        ],
      },
    };
  },
};
</script>

<style>
</style>