<template>
  <div>
    <header class="top__header bg-primary pb-0 text-white">
      <div class="container-lg">
        <div class="row mb-6">
          <div class="col-md-8">
            <div class="d-flex align-items-center">
              <div class="avatar avatar--white avatar-header flex-shrink-0">
                {{ user_metadata.name[0] }}
              </div>
              <div>
                <h1 class="main__header mb-2 text-uppercase">
                  {{ user_metadata.name }}
                </h1>
                <p class="txt__sm">
                  Get premium access to a new data compiling app, that lets you
                  do research while on the couch.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="user-social-links justify-content-md-end mt-sm-5">
              <a
                href="javascript:void(0);"
                class="user-social-link"
                target="_blank"
                ><i class="fas fa-phone-alt"></i
              ></a>
              <a
                href="javascript:void(0);"
                class="user-social-link"
                target="_blank"
                ><i class="fas fa-link"></i
              ></a>
              <a
                href="javascript:void(0);"
                class="user-social-link"
                target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a
                href="javascript:void(0);"
                class="user-social-link"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </div>
          </div>
        </div>

        <nav class="nav header-tab">
          <a
            href="javascript:void(0);"
            class="nav-link"
            :class="{ active: userTab === 'rating' }"
            @click="userTabView('rating')"
            >Rating & Review</a
          >
          <a
            href="javascript:void(0);"
            class="nav-link"
            @click="
              userTabView('lists');
              getSidebarheight();
            "
            :class="{ active: userTab === 'lists' }"
            >Products</a
          >
        </nav>
      </div>
    </header>

    <div class="container-lg">
      <div class="user-profile-view pt-6 mb-7">
        <transition name="fade">
          <div class="user-profile-rating" v-if="userTab === 'rating'">
            <div class="row">
              <div class="col-lg-3 col-sm-6 mb-4">
                <div
                  class="
                    offer__box
                    bg-white
                    align-items-start
                    rounded-0
                    py-3
                    mb-2
                    h-100
                  "
                >
                  <div class="mb-2">
                    <p class="txt__30 font-weight-bold mb-1 txt">27</p>
                    <p class="txt__sm text-muted">Data List</p>
                  </div>
                  <div class="mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="26.069"
                    >
                      <g data-name="Group 5 Copy 3" opacity=".6">
                        <g
                          fill="none"
                          stroke="#0091ff"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        >
                          <rect
                            width="28"
                            height="26.069"
                            rx="4"
                            stroke="none"
                          ></rect>
                          <rect
                            x=".75"
                            y=".75"
                            width="26.5"
                            height="24.569"
                            rx="3.25"
                          ></rect>
                        </g>
                        <g
                          data-name="Group 4"
                          transform="translate(7.724 5.793)"
                        >
                          <ellipse
                            cx="3.361"
                            cy="3.335"
                            rx="3.361"
                            ry="3.335"
                            transform="translate(2.881)"
                            fill="#0091ff"
                          ></ellipse>
                          <path
                            d="M0 15.243a6 6 0 016.242-5.716 6 6 0 016.242 5.716"
                            fill="none"
                            stroke="#0091ff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            stroke-width="1.5"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 mb-4">
                <div
                  class="
                    offer__box
                    bg-white
                    align-items-start
                    rounded-0
                    mb-2
                    py-3
                    h-100
                  "
                >
                  <div class="mb-2">
                    <Rating
                      v-bind:average_rating="user_metadata.average_rating"
                    />
                    <p class="txt__sm text-muted">Avg. Rating</p>
                  </div>
                  <div class="mb-2 text-right">
                    <p class="txt__30 font-weight-bold txt">
                      {{ user_metadata.total_reviews }}
                    </p>
                    <p class="txt__sm text-muted">Reviews</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 mb-4">
                <div
                  class="
                    offer__box
                    bg-white
                    align-items-start
                    rounded-0
                    py-3
                    h-100
                    mb-2
                  "
                >
                  <div class="mb-2">
                    <p class="txt__30 font-weight-bold mb-1 txt">
                      {{ user_metadata.total_downloads }}
                    </p>
                    <p class="txt__sm text-muted">Download</p>
                  </div>
                  <div class="mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25px"
                      height="25px"
                      fill="currentColor"
                      class="bi bi-cloud-arrow-down text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
                      />
                      <path
                        d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 mb-4">
                <div
                  class="
                    offer__box
                    bg-white
                    align-items-start
                    py-3
                    h-100
                    rounded-0
                    mb-2
                  "
                >
                  <div class="mb-2">
                    <p class="font-weight-bold mb-1 txt txt__30">
                      {{ user_metadata.total_views }}
                    </p>
                    <p class="txt__sm text-muted">View</p>
                  </div>
                  <div class="mb-2">
                    <svg
                      width="33"
                      height="24"
                      viewBox="0 0 33 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.7068 12.9748C12.3169 12.5839 11.6831 12.5839 11.2932 12.9748C10.9023 13.3647 10.9023 13.9986 11.2932 14.3885C11.6831 14.7794 12.3169 14.7794 12.7068 14.3885C13.0977 13.9986 13.0977 13.3647 12.7068 12.9748Z"
                        fill="#0091FF"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15 16.1816C15 15.3545 15.6729 14.6816 16.5 14.6816C17.3271 14.6816 18 15.3545 18 16.1816C18 17.0087 17.3271 17.6816 16.5 17.6816C15.6729 17.6816 15 17.0087 15 16.1816ZM16 16.1816C16 16.4573 16.2243 16.6816 16.5 16.6816C16.7757 16.6816 17 16.4573 17 16.1816C17 15.9059 16.7757 15.6816 16.5 15.6816C16.2243 15.6816 16 15.9059 16 16.1816Z"
                        fill="#0091FF"
                      ></path>
                      <path
                        d="M16 10.6816C15.3014 10.6816 14.6258 10.8228 13.9922 11.1013C13.6762 11.2403 13.5327 11.609 13.6716 11.925C13.8104 12.241 14.1793 12.3845 14.4952 12.2457C14.9693 12.0373 15.4756 11.9316 16 11.9316C18.0677 11.9316 19.75 13.6139 19.75 15.6816C19.75 17.7494 18.0677 19.4316 16 19.4316C13.9323 19.4316 12.25 17.7494 12.25 15.6816C12.25 15.6603 12.2499 15.6394 12.2504 15.618C12.2584 15.273 11.9852 14.9867 11.6401 14.9787C11.2945 14.9701 11.0088 15.2439 11.0008 15.589C11.0001 15.6201 11 15.6505 11 15.6816C11 18.4386 13.243 20.6816 16 20.6816C18.757 20.6816 21 18.4386 21 15.6816C21 12.9246 18.757 10.6816 16 10.6816Z"
                        fill="#0091FF"
                      ></path>
                      <path
                        d="M17 0.681641C16.4477 0.681641 16 0.961453 16 1.30664V5.05664C16 5.40183 16.4477 5.68164 17 5.68164C17.5523 5.68164 18 5.40183 18 5.05664V1.30664C18 0.961453 17.5523 0.681641 17 0.681641Z"
                        fill="#0091FF"
                      ></path>
                      <path
                        d="M9.85924 6.86114L7.82049 4.8224C7.63281 4.63472 7.32849 4.63472 7.14076 4.8224C6.95308 5.01008 6.95308 5.3144 7.14076 5.50212L9.17951 7.54086C9.36719 7.72854 9.67156 7.72859 9.85924 7.54086C10.0469 7.35319 10.0469 7.04887 9.85924 6.86114Z"
                        fill="#0091FF"
                      ></path>
                      <path
                        d="M25.8592 4.8224C25.6716 4.63472 25.3672 4.63472 25.1795 4.8224L23.1408 6.86114C22.9531 7.04882 22.9531 7.35314 23.1408 7.54086C23.3284 7.72854 23.6328 7.72859 23.8205 7.54086L25.8592 5.50212C26.0469 5.31445 26.0469 5.01012 25.8592 4.8224Z"
                        fill="#0091FF"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5 7.68164C22.6647 7.68164 28.7442 11.3611 32.8386 15.7869C33.0742 16.0416 33.0489 16.4305 32.7822 16.6555C32.5145 16.8811 32.1073 16.8555 31.8724 16.6016C31.735 16.4532 31.5943 16.3053 31.4518 16.1582C28.2669 20.7529 22.4933 23.6816 16.5 23.6816C10.5067 23.6816 4.73311 20.7529 1.54816 16.1582C1.40565 16.3052 1.26495 16.4531 1.1276 16.6016C0.892026 16.8563 0.484682 16.8805 0.217846 16.6555C-0.0489257 16.4305 -0.0741913 16.0416 0.161449 15.7869C4.25506 11.362 10.3345 7.68164 16.5 7.68164ZM2.46926 15.2521C5.3837 19.6399 10.8336 22.4509 16.5 22.4509C22.1664 22.4509 27.6163 19.6399 30.5307 15.252C28.2344 13.1037 22.9286 8.91241 16.5 8.91241C10.0715 8.91241 4.7656 13.1037 2.46926 15.2521Z"
                        fill="#0091FF"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="py-4 bg-white mb-4 data-statics">
                  <div class="px-4 pb-4">
                    <p class="txt__sm txt__light">Data Download Statistics</p>
                  </div>

                  <!-- <DownloadStatsChart v-bind:data="char.data"/> -->
                  <DownloadStatsChart v-bind:chartdata="chartData" />
                  
                </div>

                <div class="bg-white review-Area">
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-between
                      py-3
                      px-4
                      border-bottom
                    "
                  >
                    <p class="txt__md font-weight-bold">Reviews</p>
                    <select
                      class="custom-select custom-select--list"
                      v-model="sort"
                      @change="getUserReview"
                    >
                      <option selected value="">Sort By</option>
                      <option value="desc">Positive</option>
                      <option value="asc">Negative</option>
                    </select>
                  </div>
                  <p class="pl-4 pb-2 pt-2" v-if="!has_review">
                    No reviews yet!
                  </p>
                  <div
                    class="user-review"
                    v-for="(userReview, index) in userReviews"
                    :key="index"
                  >
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        py-3
                        px-4
                        flex-wrap
                        pb-4
                      "
                    >
                      <div class="d-flex align-items-center mb-2">
                        <div class="avatar flex-shrink-0">
                          {{ firstChar(userReview.reviewer_name) | capitalize }}
                        </div>
                        <p class="txt__sm">{{ userReview.reviewer_name }}</p>
                      </div>

                      <div class="product__reviews mb-2">
                        <Rating v-bind:average_rating="userReview.rating" />
                      </div>
                    </div>
                    <div class="pb-2 px-4">
                      <div class="pb-3 border-bottom">
                        <p class="txt__sm">
                          {{ userReview.review }}
                        </p>
                      </div>
                    </div>

                    <div class="py-3 px-4">
                      <div class="form-row">
                        <div class="col-md-5">
                          <p class="txt__md font-weight-bold mb-2">
                            {{ userReview.Product.title }}
                          </p>
                          <div
                            v-for="(userReviewIndustry, index) in userReview
                              .Product.industries"
                            :key="index"
                          >
                            <p class="txt__sm txt__light">
                              {{ userReviewIndustry }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-7">
                          <div class="form-row">
                            <div class="col mb-2">
                              <p class="txt__lg font-weight-bold mb-2">
                                {{ userReview.Product.row_count }}
                              </p>
                              <div class="d-flex align-items-center">
                                <svg
                                  width="13"
                                  height="14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="6.281"
                                    cy="2.844"
                                    r="2.844"
                                    fill="#0091FF"
                                  ></circle>
                                  <path
                                    d="M1 13c0-2.692 2.365-4.875 5.281-4.875 2.917 0 5.282 2.183 5.282 4.875"
                                    stroke="#0091FF"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                                <p class="txt__xs ml-2">Data</p>
                              </div>
                            </div>
                            <div class="col mb-2">
                              <p class="txt__lg font-weight-bold mb-2">
                                {{ userReview.Product.view }}
                              </p>
                              <div class="d-flex align-items-center">
                                <svg
                                  width="21"
                                  height="15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.996 7.31a.507.507 0 00-.72 0 .512.512 0 000 .722c.2.2.522.2.72 0a.512.512 0 000-.723z"
                                    fill="#0091FF"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.164 9.204c0-.564.456-1.022 1.018-1.022a1.022 1.022 0 010 2.045 1.022 1.022 0 01-1.018-1.023zm.678 0c0 .188.153.341.34.341a.34.34 0 000-.682.34.34 0 00-.34.341z"
                                    fill="#0091FF"
                                  ></path>
                                  <path
                                    d="M10.182 6.137c-.427 0-.84.086-1.227.257a.384.384 0 00-.196.506c.085.194.31.282.504.196.29-.127.599-.192.919-.192a2.299 2.299 0 012.29 2.3 2.299 2.299 0 01-2.29 2.302A2.299 2.299 0 017.89 9.205v-.04a.382.382 0 10-.763-.018v.058a3.065 3.065 0 003.054 3.068 3.065 3.065 0 003.054-3.068 3.065 3.065 0 00-3.054-3.068zM10.69 0c-.28 0-.508.172-.508.384v2.3c0 .212.228.384.509.384s.509-.172.509-.383V.384c0-.212-.228-.384-.51-.384zM6.014 3.532L4.63 2.142a.325.325 0 00-.462 0 .329.329 0 000 .463l1.384 1.39a.325.325 0 00.462 0 .329.329 0 000-.463zM16.195 2.142a.325.325 0 00-.461 0l-1.384 1.39a.329.329 0 000 .463.325.325 0 00.462 0l1.383-1.39a.329.329 0 000-.463z"
                                    fill="#0091FF"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10.182 4.09c3.804 0 7.555 2.353 10.082 5.182a.39.39 0 01-.035.555.4.4 0 01-.561-.034 16.74 16.74 0 00-.26-.284c-1.965 2.937-5.528 4.81-9.226 4.81-3.699 0-7.261-1.873-9.227-4.81-.088.094-.174.189-.26.284a.4.4 0 01-.56.034.39.39 0 01-.035-.555C2.626 6.443 6.377 4.09 10.182 4.09zM1.524 8.93c1.798 2.805 5.161 4.601 8.658 4.601 3.496 0 6.86-1.796 8.658-4.601-1.417-1.373-4.691-4.052-8.658-4.052-3.967 0-7.241 2.679-8.658 4.052z"
                                    fill="#0091FF"
                                  ></path>
                                </svg>
                                <p class="txt__xs ml-2">View</p>
                              </div>
                            </div>
                            <div class="col mb-2">
                              <p class="txt__lg font-weight-bold mb-2">
                                {{ userReview.Product.download }}
                              </p>
                              <div class="d-flex align-items-center">
                                <svg
                                  width="19"
                                  height="14"
                                  viewBox="0 0 19 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <mask
                                    id="path-1-outside-1"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="19"
                                    height="14"
                                    fill="black"
                                  >
                                    <rect fill="white" width="19" height="14" />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M9.47132 1C12.0409 1 14.1835 2.86784 14.6635 5.34392C16.4919 5.47709 17.9426 7.01742 17.9426 8.91765C17.9426 10.9043 16.3613 12.5166 14.4129 12.5166H5.23566C2.89546 12.5166 1 10.584 1 8.19788C1 5.97013 2.65545 4.13829 4.77679 3.90433C5.66277 2.18046 7.43116 1 9.47132 1ZM10.8832 10.3572V7.4781H13.0011L9.47132 3.87916L5.94163 7.47807H8.05946V10.3572H10.8832Z"
                                    />
                                  </mask>
                                  <path
                                    d="M14.6635 5.34392L13.6818 5.53425L13.8275 6.28567L14.5909 6.34128L14.6635 5.34392ZM4.77679 3.90433L4.88641 4.89831L5.42055 4.8394L5.6662 4.36144L4.77679 3.90433ZM10.8832 7.4781V6.4781H9.88322V7.4781H10.8832ZM10.8832 10.3572V11.3572H11.8832V10.3572H10.8832ZM13.0011 7.4781V8.4781H15.3825L13.715 6.7779L13.0011 7.4781ZM9.47132 3.87916L10.1853 3.17896L9.47132 2.45102L8.75738 3.17896L9.47132 3.87916ZM5.94163 7.47807L5.22769 6.77786L3.56018 8.47807H5.94163V7.47807ZM8.05946 7.47807H9.05946V6.47807H8.05946V7.47807ZM8.05946 10.3572H7.05946V11.3572H8.05946V10.3572ZM15.6453 5.15358C15.0784 2.22967 12.5422 0 9.47132 0V2C11.5397 2 13.2886 3.50602 13.6818 5.53425L15.6453 5.15358ZM18.9426 8.91765C18.9426 6.49817 17.0921 4.51815 14.7362 4.34656L14.5909 6.34128C15.8917 6.43602 16.9426 7.53667 16.9426 8.91765H18.9426ZM14.4129 13.5166C16.9318 13.5166 18.9426 11.4382 18.9426 8.91765H16.9426C16.9426 10.3704 15.7908 11.5166 14.4129 11.5166V13.5166ZM5.23566 13.5166H14.4129V11.5166H5.23566V13.5166ZM0 8.19788C0 11.1179 2.32496 13.5166 5.23566 13.5166V11.5166C3.46596 11.5166 2 10.0501 2 8.19788H0ZM4.66717 2.91036C2.0302 3.20118 0 5.46921 0 8.19788H2C2 6.47105 3.2807 5.07539 4.88641 4.89831L4.66717 2.91036ZM9.47132 0C7.0323 0 4.93338 1.41199 3.88738 3.44722L5.6662 4.36144C6.39216 2.94893 7.83002 2 9.47132 2V0ZM9.88322 7.4781V10.3572H11.8832V7.4781H9.88322ZM13.0011 6.4781H10.8832V8.4781H13.0011V6.4781ZM8.75738 4.57937L12.2871 8.17831L13.715 6.7779L10.1853 3.17896L8.75738 4.57937ZM6.65557 8.17828L10.1853 4.57937L8.75738 3.17896L5.22769 6.77786L6.65557 8.17828ZM8.05946 6.47807H5.94163V8.47807H8.05946V6.47807ZM9.05946 10.3572V7.47807H7.05946V10.3572H9.05946ZM10.8832 9.3572H8.05946V11.3572H10.8832V9.3572Z"
                                    fill="#0091FF"
                                    mask="url(#path-1-outside-1)"
                                  />
                                </svg>

                                <p class="txt__xs ml-2">Download</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pt-3">
                  <button
                    class="full-width-action-btn"
                    v-if="load_more && !loading"
                    @click="getMoreUserReview"
                  >
                    See More
                  </button>
                  <button
                    class="spin-wrap bg-white border-none out w-100"
                    v-if="loading"
                  >
                    <i class="fas fa-circle-notch fa-spin"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product__card">
                  <div class="product__card__header px-4">
                    <p class="txt__md font-weight-bold txt mb-2">
                      Customer reviews
                    </p>
                    <p class="txt__sm txt__light">
                      {{ user_metadata.total_rating }} global ratings
                    </p>
                    <div class="d-flex align-items-center pt-4 pb-2 flex-wrap">
                      <Rating
                        v-bind:average_rating="user_metadata.average_rating"
                      />
                      <p class="txt__sm mb-1">
                        {{ user_metadata.average_rating }} out of 5
                      </p>
                    </div>
                  </div>
                  <div class="py-3 px-4">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        flex-wrap
                      "
                    >
                      <div class="d-flex align-items-center">
                        <Rating v-bind:average_rating="5" />
                        <p class="txt__sm">5 star</p>
                      </div>

                      <p class="txt__sm mb-1">
                        {{ user_metadata.ratings["5"] }}
                      </p>
                    </div>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        flex-wrap
                      "
                    >
                      <div class="d-flex align-items-center">
                        <Rating v-bind:average_rating="4" />

                        <p class="txt__sm">4 star</p>
                      </div>
                      <p class="txt__sm mb-1">
                        {{ user_metadata.ratings["4"] }}
                      </p>
                    </div>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        flex-wrap
                      "
                    >
                      <div class="d-flex align-items-center">
                        <Rating v-bind:average_rating="3" />

                        <p class="txt__sm">3 star</p>
                      </div>
                      <p class="txt__sm mb-1">
                        {{ user_metadata.ratings["3"] }}
                      </p>
                    </div>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        flex-wrap
                      "
                    >
                      <div class="d-flex align-items-center">
                        <Rating v-bind:average_rating="2" />

                        <p class="txt__sm">2 star</p>
                      </div>
                      <p class="txt__sm mb-1">
                        {{ user_metadata.ratings["2"] }}
                      </p>
                    </div>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        flex-wrap
                      "
                    >
                      <div class="d-flex align-items-center">
                        <Rating v-bind:average_rating="1" />

                        <p class="txt__sm">1 star</p>
                      </div>
                      <p class="txt__sm mb-1">
                        {{ user_metadata.ratings["1"] }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="product__card">
                  <div class="product__card__header px-4">
                    <p class="txt__md font-weight-bold txt mb-2">
                      Top positive review
                    </p>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        mr-2
                        pb-2
                        border-bottom
                        flex-wrap
                      "
                    >
                      <p class="txt__sm mb-1">All positive reviews</p>
                      <div class="product__reviews mb-1">
                        <Rating
                          v-bind:average_rating="userPositiveReview.rating"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="py-3 px-4 pb-4" v-if="has_positive_review">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        flex-wrap
                        pb-2
                      "
                    >
                      <div class="d-flex align-items-center mb-2">
                        <div class="avatar flex-shrink-0">
                          {{
                            firstChar(userPositiveReview.reviewer_name)
                              | capitalize
                          }}
                        </div>
                        <p class="txt__sm">
                          {{ userPositiveReview.reviewer_name }}
                        </p>
                      </div>
                      <p class="txt__sm mb-2">
                        {{ formatDate(userPositiveReview.created_at) }}
                      </p>
                    </div>

                    <p class="txt__sm">
                      {{ userPositiveReview.review }}
                    </p>
                  </div>
                  <p class="pl-4 pb-2 pt-2" v-if="!has_positive_review">
                    No reviews yet!
                  </p>
                </div>

                <div class="product__card">
                  <div class="product__card__header px-4">
                    <p class="txt__md font-weight-bold txt mb-2">
                      Top critical review
                    </p>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        mr-2
                        pb-2
                        border-bottom
                        flex-wrap
                      "
                    >
                      <p class="txt__sm mb-1">All critical reviews</p>
                      <div class="product__reviews mb-1">
                        <Rating
                          v-bind:average_rating="userCriticalReview.rating"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="py-3 px-4 pb-4" v-if="has_critical_review">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        flex-wrap
                        pb-2
                      "
                    >
                      <div class="d-flex align-items-center mb-2">
                        <div class="avatar flex-shrink-0">
                          {{
                            firstChar(userCriticalReview.reviewer_name)
                              | capitalize
                          }}
                        </div>
                        <p class="txt__sm">
                          {{ userCriticalReview.reviewer_name }}
                        </p>
                      </div>
                      <p class="txt__sm mb-2">
                        {{ formatDate(userCriticalReview.created_at) }}
                      </p>
                    </div>

                    <p class="txt__sm">
                      {{ userCriticalReview.review }}
                    </p>
                  </div>
                  <p class="pl-4 pb-2 pt-2" v-if="!has_critical_review">
                    No reviews yet!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="user-profile-lists" v-if="userTab === 'lists'">
            <nav class="listNav pt-0">
              <div class="listNav__text d-flex align-items-center">
                <button
                  class="list-filter-toggler d-xl-none mr-2"
                  @click="sidebarOpen = !sidebarOpen"
                >
                  <i class="fas fa-bars"></i>
                </button>
                <p class="txt__md font-weight-bold">Filter</p>
              </div>
              <div class="listNav__filter d-flex align-items-center">
                <p class="txt__xs mr-4 current-list-total">
                   <strong>{{ pagination.to }}</strong> of
            <strong>{{ pagination.total }}</strong> results
                </p>
                <select class="custom-select custom-select--list"   v-model="sort"
            @change="sortProduct">
                  <option selected value="">Sort By</option>
            <option value="asc">Price low to high</option>
            <option value="desc">Price high to low</option>
                </select>
              </div>
            </nav>
            <div
              class="list__page"
              :style="{ 'min-height': filterBarHeight + 30 + 'px' }"
              :class="{ 'sidebar-open': sidebarOpen }"
            >
              <aside class="list__siderbar" ref="filterSidebar" :class="sidebarOpen? 'active' : '' " >
                <div class="d-flex justify-content-end d-xl-none pr-4 pt-3">
                  <button
                    class="list-filter-toggler"
                    @click="sidebarOpen = false"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <div class="filer-box bg-white py-3">
                  <label class="txt__sm font-weight-bold mb-2">Search</label>
                  <input
              type="search"
              class="form-control"
              :class="[search.length > 0 ? '' : 'form-control-search']"
              placeholder="Search by"
              v-model="search"
            />
                </div>
                <div class="filer-box bg-white py-3">
                  <div class="range-slider-wrapper">
                    <VueSlider
                     v-model="priceRange"
                :enable-cross="false"
                :min-range="1"
                :max="50000"
                :interval="1"
                v-bind="{ ...rangeOptions }"
                    ></VueSlider>
                  </div>

                  <div class="d-flex justify-content-between mt-2">
                     <p class="txt__xs">
                Min: <span>{{ priceRange[0] }}</span>
              </p>
              <p class="txt__xs">
                Max: <span>{{ priceRange[1] }}</span>
              </p>
                  </div>
                </div>
                <div class="filer-box bg-white py-3">
                  <label class="txt__sm font-weight-bold mb-2">Date</label>
                  <VueCtkDateTimePicker
                    v-model="startDate"
                    id="startdate"
                    class="custom-date-picker mb-3"
                    color="#0091ff"
                    :onlyDate="true"
                    :noButtonNow="true"
                    :label="'Start date'"
                  />
                  <VueCtkDateTimePicker
                    v-model="endDate"
                    id="enddate"
                    class="custom-date-picker"
                    color="#0091ff"
                    :onlyDate="true"
                    :noButtonNow="true"
                    :label="'End date'"
                  />
                </div>
                <div class="filer-box bg-white">
                  <!-- <div
                    class="industry-filter-button py-3"
                    @click="openFilterModal()"
                    :class="{ active: IndustryFilterModalOpen }"
                  >
                    <span class="txt__sm font-weight-bold">Industry</span>
                    <span
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="7"
                        viewBox="0 0 4 7"
                      >
                        <path
                          d="M3.5,0,7-4H0Z"
                          transform="translate(4 7) rotate(-90)"
                          fill="#0091ff"
                        /></svg
                    ></span>
                  </div> -->
                  <span class="txt__sm font-weight-bold">Industry</span>
                  <multiselect
                    v-model="IndustryValue"
                    :options="industries"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                  >
                  </multiselect>
                </div>
                <div class="filer-box bg-white py-3">
            <label class="txt__sm font-weight-bold mb-2">Rating</label>

            <ul class="raling__lists">
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                    v-model="ratings"
                    value="5"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck1"
                  ></label>
                </div>
                <span class="txt__xs mx-2">5</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck2"
                    v-model="ratings"
                    value="4"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck2"
                  ></label>
                </div>
                <span class="txt__xs mx-2">4</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck3"
                    v-model="ratings"
                    value="3"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck3"
                  ></label>
                </div>
                <span class="txt__xs mx-2">3</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck4"
                    v-model="ratings"
                    value="2"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck4"
                  ></label>
                </div>
                <span class="txt__xs mx-2">2</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck5"
                    v-model="ratings"
                    value="1"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck5"
                  ></label>
                </div>
                <span class="txt__xs mx-2">1</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
            </ul>
          </div>
          <div class="filer-box bg-white py-3">
            <label class="txt__sm font-weight-bold mb-2">Download</label>
            <div class="range-slider-wrapper">
              <VueSlider
                v-model="downloadRange"
                :enable-cross="false"
                :min-range="1"
                :max="50000"
                :interval="1"
                v-bind="{ ...rangeOption }"
              ></VueSlider>
            </div>

            <div class="d-flex justify-content-between mt-2">
              <p class="txt__xs">
                Min: <span>{{ downloadRange[0] }}</span>
              </p>
              <p class="txt__xs">
                Max: <span>{{ downloadRange[1] }}</span>
              </p>
            </div>
            <div class="d-flex justify-content-end mt-4">
              <button
                class="btn-wrap btn-primary btn-sm text-white"
                @click="getProducts"
              >
                Apply
              </button>
              <button
                class="btn-wrap btn-danger btn-sm text-white ml-2"
                @click="clearFilter"
              >
                Reset
              </button>
            </div>
          </div>
        </aside>
              <div class="lists">
                <div class="row">
                  <div
                    class="col-lg-6 col-md-6 col-sm-12 mb-4 bg-red"
                    v-for="(list, index) in Lists"
                    :key="index"
                  >
                    <div
                      class="product__card h-100"
                      :class="{ 'product__card--recommend': list.recommend }"
                    >
                      <div class="product__card__header">
                        <router-link
                          :to="{
                            path: '/marketplace-details',
                            query: { product: list.id },
                          }"
                        >
                          <p class="txt__md font-weight-bold txt overflow-text">
                            {{ list.title }}
                          </p></router-link
                        >

                        <div
                          class="product__card__tags"
                          v-if="list.industries.length > 0"
                        >
                          <a
                            href="javascript:void(0);"
                            class="product-tag"
                            v-for="(industry, j) in list.industries"
                            :key="j"
                          >
                            {{ industry }}
                          </a>
                        </div>
                      </div>

                      <div class="product__card__review">
                        <div class="product__card__reviews mb-2">
                          <Rating v-bind:average_rating="list.average_rating" />
                          <p class="txt__sm">{{ list.total_review }} reviews</p>
                        </div>
                        <div class="product__card__reviews__update mb-2">
                          <p class="txt__xs mb-2 text-muted">Updated on</p>
                          <p class="txt__xs">
                            {{ formatDate(list.updated_at) }}
                          </p>
                          <!-- <p class="txt__xs">{{ list.updated_at }}</p> -->
                        </div>
                      </div>

                      <div class="product__card__stats">
                        <div class="form-row">
                          <div class="col mb-2">
                            <p class="txt__lg font-weight-bold mb-2">
                              {{ list.row_count }}
                            </p>
                            <div class="d-flex align-items-center">
                              <svg
                                width="13"
                                height="14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="6.281"
                                  cy="2.844"
                                  r="2.844"
                                  fill="#0091FF"
                                />
                                <path
                                  d="M1 13c0-2.692 2.365-4.875 5.281-4.875 2.917 0 5.282 2.183 5.282 4.875"
                                  stroke="#0091FF"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <p class="txt__xs ml-2">Data</p>
                            </div>
                          </div>
                          <div class="col mb-2">
                            <p class="txt__lg font-weight-bold mb-2">
                              {{ list.view }}
                            </p>
                            <div class="d-flex align-items-center">
                              <svg
                                width="21"
                                height="15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.996 7.31a.507.507 0 00-.72 0 .512.512 0 000 .722c.2.2.522.2.72 0a.512.512 0 000-.723z"
                                  fill="#0091FF"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.164 9.204c0-.564.456-1.022 1.018-1.022a1.022 1.022 0 010 2.045 1.022 1.022 0 01-1.018-1.023zm.678 0c0 .188.153.341.34.341a.34.34 0 000-.682.34.34 0 00-.34.341z"
                                  fill="#0091FF"
                                />
                                <path
                                  d="M10.182 6.137c-.427 0-.84.086-1.227.257a.384.384 0 00-.196.506c.085.194.31.282.504.196.29-.127.599-.192.919-.192a2.299 2.299 0 012.29 2.3 2.299 2.299 0 01-2.29 2.302A2.299 2.299 0 017.89 9.205v-.04a.382.382 0 10-.763-.018v.058a3.065 3.065 0 003.054 3.068 3.065 3.065 0 003.054-3.068 3.065 3.065 0 00-3.054-3.068zM10.69 0c-.28 0-.508.172-.508.384v2.3c0 .212.228.384.509.384s.509-.172.509-.383V.384c0-.212-.228-.384-.51-.384zM6.014 3.532L4.63 2.142a.325.325 0 00-.462 0 .329.329 0 000 .463l1.384 1.39a.325.325 0 00.462 0 .329.329 0 000-.463zM16.195 2.142a.325.325 0 00-.461 0l-1.384 1.39a.329.329 0 000 .463.325.325 0 00.462 0l1.383-1.39a.329.329 0 000-.463z"
                                  fill="#0091FF"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10.182 4.09c3.804 0 7.555 2.353 10.082 5.182a.39.39 0 01-.035.555.4.4 0 01-.561-.034 16.74 16.74 0 00-.26-.284c-1.965 2.937-5.528 4.81-9.226 4.81-3.699 0-7.261-1.873-9.227-4.81-.088.094-.174.189-.26.284a.4.4 0 01-.56.034.39.39 0 01-.035-.555C2.626 6.443 6.377 4.09 10.182 4.09zM1.524 8.93c1.798 2.805 5.161 4.601 8.658 4.601 3.496 0 6.86-1.796 8.658-4.601-1.417-1.373-4.691-4.052-8.658-4.052-3.967 0-7.241 2.679-8.658 4.052z"
                                  fill="#0091FF"
                                />
                              </svg>
                              <p class="txt__xs ml-2">View</p>
                            </div>
                          </div>
                          <div class="col mb-2">
                            <p class="txt__lg font-weight-bold mb-2">
                              {{ list.download }}
                            </p>
                            <div class="d-flex align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-cloud-arrow-down text-primary"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
                                />
                                <path
                                  d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
                                />
                              </svg>
                              <p class="txt__xs ml-2">Download</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="product__card__uploader">
                        <div class="product__uploader">
                          <router-link
                            :to="{
                              path: '/user-profile',
                              query: { profile: list.seller_id },
                            }"
                          >
                            <div class="avatar">
                              {{ firstChar(list.seller_name) | capitalize }}
                            </div>
                          </router-link>
                          <router-link
                            :to="{
                              path: '/user-profile',
                              query: { profile: list.seller_id },
                            }"
                          >
                            <div>
                              <p class="txt__10">Uploaded by</p>
                              <p class="txt__sm txt">{{ list.seller_name }}</p>
                            </div>
                          </router-link>
                        </div>
                        <button
                          class="product__cart__btn"
                          title="add to cart"
                          @click="addToCart(index)"
                          v-if="!list.owned"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            fill="currentColor"
                            class="bi bi-cart-plus"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z"
                            />
                            <path
                              d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                            />
                          </svg>
                        </button>
                        <button
                          :class="[
                            'product__purchase__btn',
                            list.purchased ? 'sold' : '',
                          ]"
                          @click="getpurchasemodalopen(list.id, index)"
                        >
                          <svg
                            width="20"
                            height="18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.281 3.008C6.281 1.054 9.68 0 12.877 0s6.595 1.054 6.595 3.008V15.04c0 1.734-2.685 2.734-5.346 2.958l-.051.002a.6.6 0 01-.05-1.2c2.773-.233 4.248-1.171 4.248-1.76v-.628c-.698.446-1.674.8-2.893 1.015a.6.6 0 01-.209-1.184c2.016-.357 3.102-1.095 3.102-1.61v-.627c-.698.446-1.674.8-2.893 1.015a.6.6 0 01-.209-1.184c2.016-.357 3.102-1.096 3.102-1.61V9.6c-.698.448-1.674.8-2.893 1.016a.6.6 0 01-.209-1.184c2.016-.358 3.102-1.096 3.102-1.61v-.644c-1.03.658-2.585 1.064-4.147 1.195h-.005l-.046.002a.601.601 0 01-.05-1.2c2.773-.233 4.248-1.171 4.248-1.76v-.626c-1.272.805-3.373 1.228-5.396 1.228-2.023 0-4.124-.423-5.396-1.228v.626a.6.6 0 11-1.2 0V3.008zm1.2 0c0 .735 2.102 1.805 5.396 1.805s5.396-1.07 5.396-1.805c0-.735-2.102-1.805-5.396-1.805S7.48 2.273 7.48 3.008z"
                              fill="#fff"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0 10.38c0-1.905 3.398-2.932 6.595-2.932 3.197 0 6.596 1.027 6.596 2.931v4.69C13.19 16.973 9.792 18 6.595 18S0 16.973 0 15.069v-4.69zm6.595 6.448c3.295 0 5.397-1.043 5.397-1.76v-.61c-1.273.784-3.374 1.197-5.397 1.197s-4.124-.413-5.396-1.197v.61c0 .717 2.102 1.76 5.396 1.76zm5.397-4.104c0 .716-2.102 1.759-5.397 1.759-3.294 0-5.396-1.043-5.396-1.759v-.61c1.272.784 3.373 1.196 5.396 1.196 2.023 0 4.124-.412 5.397-1.197v.611zM1.199 10.38c0 .717 2.102 1.759 5.396 1.759 3.295 0 5.397-1.042 5.397-1.759 0-.716-2.102-1.758-5.397-1.758-3.294 0-5.396 1.042-5.396 1.758z"
                              fill="#fff"
                            />
                          </svg>

                          {{
                            !list.purchased ? list.price + "cr" : "Purchased"
                          }}
                        </button>
                      </div>

                      <div class="product__card--ribbon" v-if="list.owned">
                        Owned
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    class="full-width-action-btn"
                    v-if="load_more && !loading"
                    @click="paginate"
                  >
                    Load More
                  </button>

                  <button
                    class="spin-wrap bg-white border-none out w-100"
                    v-if="loading"
                  >
                    <i class="fas fa-circle-notch fa-spin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <!-- MODAL -->
    <Dialog />
    <!-- <IndustryFilterModal /> -->
  </div>
</template>

<script>
import DownloadStatsChart from "@/components/charts/DownloadStatsChart";
import VueSlider from "vue-slider-component";
import Multiselect from "vue-multiselect";
// import productLists from "@/static/lists";
// import Pagination from "@/components/layout/Pagination";
// import IndustryFilterModal from "@/components/modal/IndustryFIlter";
import { axiosP, axiosHeaderP } from "@/services/axiosinstance";
import Rating from "@/components/common/Rating";
import Dialog from "@/components/modal/Dialog";

export default {
  name: "UserDetails",
  components: {
    DownloadStatsChart,
    Multiselect,
    VueSlider,
    // Pagination,
    Rating,
    Dialog,
    // IndustryFilterModal,
  },
  data() {
    return {
      userTab: "rating",
      user_metadata: "",

      IndustryValue: [],
      industries: [],

      startDate: "",
      endDate: "",
     priceRange: [0, 50000],
      downloadRange: [0, 1000000],

      rangeOptions: {
        dotSize: 19,
        height: 1,
      },
      ratings: [],
      userPositiveReview: [],
      userCriticalReview: [],
      userReviews: [],
      sort: "",
      search:"",
      pagination: {
        to: "",
        total: "",
      },
      page: 1,
      load_more: false,
      loading: false,

      Lists: {},
      sidebarOpen: false,
      IndustryFilterModalOpen: false,
      filterBarHeight: 700,
      has_review: true,
      has_positive_review: true,
      has_critical_review: true,
    };
  },
  mounted() {
    // console.log("hi");
    this.getSidebarheight();
  },
  created() {
    this.getUserDetails();
    this.getChartDownloads();
    this.getUserReview();
    this.getTopPositiveUserReview();
    this.getTopCriticalUserReview();
    this.getIndustries();
    this.getProducts();
    // if localStorage.getItem
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  watch: {
    search: function (newVal, oldVal) {
      if (newVal.trim() != oldVal.trim()) {
        this.page = 1;
        this.Lists = [];
        this.getProducts();
      }
    },
  },
  methods: {
    userTabView(tab) {
      this.userTab = tab;
    },
    openFilterModal() {
      this.$modal.show("industryFilterModal");
    },
    firstChar(seller) {
      if (seller) {
        return seller.charAt(0);
      } else {
        return;
      }
    },
    formatDate(date) {
      if (date) {
        return this.$moment(date).format("YYYY-MM-DD");
      } else {
        return "N/A";
      }
    },
    getSidebarheight() {
      if (this.$refs.filterBar) {
        const SIDEBAR_HEIGHT = this.$refs.filterBar.clientHeight;
        this.filterBarHeight = SIDEBAR_HEIGHT;
      }
    },
    sortProduct() {
      this.page = 1;
      this.getProducts();
    },
    async getUserDetails() {
      await axiosP
        .post("get-user-metadata?seller_id=" + this.$route.query.profile)
        .then((res) => {
          if (res.data.status) {
            // console.log(res.data.data);
            this.user_metadata = res.data.data;
          } else {
            alert("Error");
          }
        })
        .catch(() => {
          // console.log(err);
        });
    },

    async getChartDownloads() {
      await axiosP
        .post(
          "get-sells?seller=" +
            this.$route.query.profile +
            "&year=" +
            new Date().getFullYear()
        )
        .then((res) => {
            this.chartData = res.data.data;
            // console.log(this.chartData)
        })
        .catch((err) => {
          alert(err);
        });
    },
    async getUserReview() {
      this.formData = new FormData();
      this.formData.append("page", this.page);
      this.formData.append("limit", "2");
      this.formData.append("seller_id", this.$route.query.profile);
      this.formData.append("product_id", "");
      this.formData.append("parent_id", "");
      this.formData.append("sort_order", this.sort);
      await axiosP
        .post("get-reviews", this.formData)
        .then((res) => {
          if (res.data.status) {
            this.userReviews = res.data.data;
            this.load_more = res.data.pagination.load_more;
            this.pagination = res.data.pagination;
          } else {
            this.userReviews = [];
            this.load_more = false;
          }
          if (this.userReviews.length < 1) {
            this.has_review = false;
          }
        })
        .catch(() => {
          this.load_more = false;
          this.userReviews = [];
          // console.log();
          this.has_review = false;
        });
    },
    async getMoreUserReview() {
      this.loading = true;
      // this.getProducts();
      this.page++;
      this.formData = new FormData();
      this.formData.append("page", this.page);
      this.formData.append("limit", "2");
      this.formData.append("seller_id", this.$route.query.profile);
      this.formData.append("product_id", "");
      this.formData.append("parent_id", "");
      this.formData.append("sort_order", this.sort);
      await axiosP
        .post("get-reviews", this.formData)
        .then((res) => {
          if (res.data.status) {
            let userReviews = res.data.data;
            userReviews.map((val) => {
              this.userReviews.push(val);
            });
            this.load_more = res.data.pagination.load_more;
            this.pagination = res.data.pagination;
          } else {
            this.load_more = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.userReviews = [];
          this.loading = false;
          // console.log();
        });
    },
    async getTopPositiveUserReview() {
      this.formData = new FormData();
      this.formData.append("page", "1");
      this.formData.append("limit", "1");
      this.formData.append("seller_id", this.$route.query.profile);
      this.formData.append("product_id", "");
      this.formData.append("parent_id", "");
      this.formData.append("sort_order", "desc");
      await axiosP
        .post("get-reviews", this.formData)
        .then((res) => {
          if (res.data.status) {
            this.userPositiveReview = res.data.data[0];
          } else {
            this.userPositiveReview = [];
          }

          if (this.userPositiveReview.length < 1) {
            this.has_positive_review = false;
          }
        })
        .catch(() => {
          this.userPositiveReview = [];
          this.has_positive_review = false;

          // console.log(err);
        });
    },

    async getTopCriticalUserReview() {
      this.formData = new FormData();
      this.formData.append("page", "1");
      this.formData.append("limit", "1");
      this.formData.append("seller_id", this.$route.query.profile);
      this.formData.append("product_id", "");
      this.formData.append("parent_id", "");
      this.formData.append("sort_order", "asc");
      await axiosP
        .post("get-reviews", this.formData)
        .then((res) => {
          if (res.data.status) {
            this.userCriticalReview = res.data.data[0];
          } else {
            this.userCriticalReview = [];
          }
          if (this.userCriticalReview.length < 1) {
            this.has_critical_review = false;
          }
        })
        .catch(() => {
          this.userCriticalReview = [];
          this.has_critical_review = false;

          // console.log(err);
        });
    },
    async getIndustries() {
      this.formData = new FormData();
      await axiosP
        .post("all-industry-list", this.formData)
        .then((res) => {
          this.industries = res.data.industries;
        })
        .catch(() => {
          // console.log(err);
        });
    },
    async getProducts() {
      this.formData = new FormData();
      this.formData.append("page", this.page);
      this.formData.append("limit", 4);
      this.formData.append("search", this.search);
      this.formData.append("sort_order", this.sort);
      this.formData.append("sort_by", "price");
      this.formData.append("seller", this.$route.query.profile);
      this.formData.append("min_price", this.priceRange[0]);
      this.formData.append("max_price", this.priceRange[1]);
      this.formData.append("startDate", this.startDate);
      this.formData.append("endDate", this.endDate);
      this.formData.append("industries", this.IndustryValue);
      this.formData.append("min_download", this.downloadRange[0]);
      this.formData.append("max_download", this.downloadRange[1]);
      this.formData.append("rating", this.ratings.join());
      var instance = axiosP;

      if (this.$store.getters.getLoggedIn) {
        instance = axiosHeaderP;
      }
      await instance
        .post("products", this.formData)
        .then((res) => {
          if (res.data.status) {
            this.Lists = res.data.data;
            this.load_more = res.data.pagination.load_more;
            this.pagination = res.data.pagination;
          } else {
            this.Lists = [];
            this.load_more = false;
          }
        })
        .catch(() => {
          this.Lists = [];
          this.load_more = false;
          // console.log(err);
        });
    },
    async paginate() {
      this.loading = true;
      // this.getProducts();
      this.page++;
      this.formData = new FormData();
      this.formData.append("page", this.page);
      this.formData.append("limit", 4);
      this.formData.append("search", this.search);
      this.formData.append("sort_order", this.sort);
      this.formData.append("sort_by", "price");
      this.formData.append("seller", this.$route.query.profile);
      this.formData.append("min_price", this.priceRange[0]);
      this.formData.append("max_price", this.priceRange[1]);
      this.formData.append("startDate", this.startDate);
      this.formData.append("endDate", this.endDate);
      this.formData.append("industries", this.IndustryValue);
      this.formData.append("min_download", this.downloadRange[0]);
      this.formData.append("max_download", this.downloadRange[1]);
      this.formData.append("rating", this.ratings.join());
      var instance = axiosP;

      if (this.$store.getters.getLoggedIn) {
        instance = axiosHeaderP;
      }
      await instance
        .post("products", this.formData)
        .then((res) => {
          if (res.data.status) {
            let Lists = res.data.data;
            Lists.map((val) => {
              this.Lists.push(val);
            });
            this.load_more = res.data.pagination.load_more;
            this.pagination = res.data.pagination;
          } else {
            this.load_more = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.Lists = [];
          this.loading = false;
          // console.log(err);
        });
    },
    isExistCart(id) {
      let cart = this.$store.getters.getCart;
      console.log("inp:  ", id);
      for (let i = 0; i < cart.length; i++) {
        console.log("exo:  ", cart[i].id);
        if (cart[i].id == id) {
          console.log("exist func");
          return true;
        }
      }
      return false;
    },
    addToCart(index) {
      let product = this.Lists[index];

          let result = this.$helpers.addToCart(product)
      if (result=="exists"){
        this.$toasted.show("item already added on your cart !", {
                className: "succes-class",
        });
        return;
      }
      if (result=="success"){
        this.$toasted.show("Added to cart!", {
                className: "succes-class",
        });
        return;
      }
    },
      clearFilter() {
      this.search = "";
      this.priceRange = ["", ""];
      this.startDate = "";
      this.endDate = "";
      this.downloadRange = ["", ""];
      this.ratings = [];
      this.IndustryValue = [];
      this.getProducts();
    },
  },
};
</script>

<style lang="scss" scoped>
.offer__box {
  box-shadow: 0px 0px 18px 0px #80808029;
}
.spin-wrap {
  outline: none;
  border: none;
  padding: 10px 0;
}
.fa-spin {
  color: #007bff;
  font-size: 30px;
}
.data-statics {
  box-shadow: 0px 0px 18px 0px #80808029;
}
.review-Area {
  box-shadow: 0px 0px 18px 0px #80808029;
}
.clear__input[data-v-0daac4f0] {
  right: 28px;
  top: 53px;
  border: none;
  background: none;
}
.list__siderbar {
  box-shadow: 0px 0px 18px 0px #80808029;
}
.top-offset {
    top: 80px;
}
.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>